import React from "react";


import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";


import "./estilo.css";
import PageTitle from "../PageTitle";

function AppHeaderInternal({title, urlBack}) {
  return (
    <AppBar className="app-header" position="fixed">
      <Container className="text-align-center" maxWidth="sm" sx={{ pl: 0, pr: 0 }}>
        
        <Toolbar className="app-header_toolbar" sx={{ display: 'flex', justifyContent: 'space-between' }}>
           <PageTitle title={title} urlBack={urlBack} />
           <Link to="/minha-conta">
          <IconButton
            size="large"
            color="primary"
            aria-label="conta do usuário atual"
            
            aria-haspopup="false">
                <AccountCircle />
          </IconButton>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AppHeaderInternal;
