import React, { useState } from "react";

import Button from "@mui/material/Button";
import FilterList from "@mui/icons-material/FilterList";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Check from "@mui/icons-material/Check";
import Badge from "@mui/material/Badge";

function BtnFilter({ filterObservable }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterActive, setFilterActive] = useState("Todos");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    
    let choose = event.currentTarget.innerText.trim();
    if( choose !== '' )
    {
      setFilterActive(choose);
      filterObservable(choose);
    }
    
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function isActive(label) {
    return filterActive === label;
  }

  function FilterMenuItem({ label, active }) {
    if (active) {
      return (
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          {label}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem onClick={handleClose}>
          <ListItemText inset>{label}</ListItemText>
        </MenuItem>
      );
    }
  }

  return (
    <Box>
      <Badge color="warning" variant="dot" invisible={ filterActive === "Todos" }>
        <Button aria-describedby={id} onClick={handleClick}>
          <FilterList />
          <Typography variant="button" sx={{ ml: 1 }}>
            filtros
          </Typography>
        </Button>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList dense>
          <FilterMenuItem label="Todos" active={isActive("Todos")} />
          <FilterMenuItem
            label="Em andamento"
            active={isActive("Em andamento")}
          />
          <FilterMenuItem label="Encerrado" active={isActive("Encerrado")} />
          <FilterMenuItem label="Cancelado" active={isActive("Cancelado")} />
        </MenuList>
      </Popover>
    </Box>
  );
}

export default BtnFilter;
