import React, { useState } from "react";

import AppHeaderInternal from "../../components/AppHeaderInternal";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import BtnSortRecent from "../../components/BtnSortRecent";
import BtnFilter from "../../components/BtnFilter";
import ListReservas from "../../components/ListReservas";

const PageReservas = () => {
  const [filterStatus, setFilterStatus] = useState("Todos");
  const [recent, setRecent] = useState(true);

  function filterObservable(status) {
    setFilterStatus(status);
  }

  function recentObservable(recent)
  {   
    setRecent(recent);
  }  

  return (
    <Container className="page-with-fixed-header">
      <AppHeaderInternal title="Reservas" urlBack="/" />
      <Container maxWidth="sm" sx={{ pb: 4, pt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <BtnSortRecent recent={recent} recentObservable={recentObservable} />
          <BtnFilter filterObservable={filterObservable} />
        </Box>
        <ListReservas filterStatus={filterStatus} recent={recent} />
      </Container>
    </Container>
  );
};

export default PageReservas;
