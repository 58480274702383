import React, { useEffect, useState } from "react";
import { apiGet } from "../../api";

import Container from '@mui/material/Container';
import AppHeaderInternal from "../../components/AppHeaderInternal";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const PageMinhaConta = () => {

  const [userEmail, setUserEmail] = useState("");
  const [empresaNome, setEmpresaNome] = useState("");

  useEffect(()=>{
    // userName
    //  empresaNome

    apiGet("usuarios/user-info")
    .then( res => {
      if( res && res.status === 200 )
      {
        setUserEmail(res.data.userEmail);
        setEmpresaNome(res.data.empresaNome);
      }
    })

  },[]);

  function btnSairSistemaClick(e)
  {
    e.preventDefault();
    window.localStorage.removeItem('token');
    window.location.href = '/auth';
  }

  return (
    <Container className="page-with-fixed-header">
      <AppHeaderInternal title="Minha Conta" urlBack="" />

      <Container maxWidth="sm" sx={{ pb: 4, pt: 4 }}>
          <Typography component="div" sx={{ fontWeight: '500', mb: 1 }}>{userEmail}</Typography>
          <Typography component="div">{empresaNome}</Typography>
          <Button variant="outlined" color="error" fullWidth onClick={btnSairSistemaClick} sx={{ mt: 3 }}>
            Sair do sistema
          </Button>
      </Container>

    </Container>
  );
};

export default PageMinhaConta;
