import React, { useState } from "react";
import { apiGet, apiPost } from "../../api";


import { Link } from "react-router-dom";
import "./estilo.css";
import AppBrand from "../../components/AppBrand";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

import { TextField, Button, Container } from "@mui/material";

const PageAuth = () => {
  const [email, setEmail] = useState("");
  const [emailFieldError, setEmailFieldError] = useState(false);
  const [emailFieldHelpText, setEmailFieldHelpText] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const ACCESS_CODE_EXPIRE = "access_code_expire";
  const RECAPTCHA_SITE_KEY = "6LcrrzwfAAAAAMCQrihnCul4oCakim_8nN0-XiL4";
  const recaptchaRef = React.createRef();

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitButtonDisable(true);

    const token = await recaptchaRef.current.executeAsync();

    apiPost(`google-recaptcha-validate`, { token: token }).then((response) => {
      
      if (response && response.status === 200) {
        apiGet(`usuarios/email-verify/${email}`).then((data) => {
          if (data) {
            setEmailFieldError(false);
            setEmailFieldHelpText("");
            setSubmitButtonDisable(false);

            if (data.status === 200) {
              apiPost("usuarios/send-access-code", {
                email: email,
              }).then((data2) => {
                if (data2 && data2.status === 200) {
                  window.localStorage.setItem(
                    ACCESS_CODE_EXPIRE,
                    moment().add(5, "minutes")
                  );
                }
                window.location.href = `auth/access-code/${email}`;
              });
            } else {
              setEmailFieldError(true);
              setEmailFieldHelpText(
                "Houve um erro no servidor, não podemos prosseguir."
              );
            }
          } else {
            setEmailFieldError(true);
            setEmailFieldHelpText("E-mail não encontrado");
            setSubmitButtonDisable(false);
          }
        });
      }
      else 
      {
        setSubmitButtonDisable(false);
        setEmailFieldError(true);
        setEmailFieldHelpText(
          "Validação de segurança antibot bloqueou essa requisição."
        );
      }
    });

    return;
  }

  return (
    <Container maxWidth="sm">
      <form
        className="auth_form"
        onSubmit={handleSubmit}
        action=""
        autoComplete="off"
      >
        <AppBrand size="big" />
        <TextField
          required
          id="email"
          type="email"
          value={email}
          onChange={handleEmail}
          label="E-mail"
          error={emailFieldError}
          helperText={emailFieldHelpText}
          fullWidth
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={RECAPTCHA_SITE_KEY}
        />
        <Button
          variant="contained"
          type="submit"
          className="auth_btnEnviar"
          disabled={submitButtonDisable}
          fullWidth
        >
          Entrar
        </Button>
      </form>
      <Link to="/novo-usuario" className="auth_btn-novo-usuario">
        <Button fullWidth>Novo usuário</Button>
      </Link>
    </Container>
  );
};

export default PageAuth;
