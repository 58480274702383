
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PageNovoUsuario from './routes/novo-usuario';
import PageNovoUsuarioMessage from './routes/novo-usuario-message';
import PageAuth from './routes/auth';
import PageAuthAccessToken from './routes/auth-access-code'
import PageHome from './routes/home';
import PageReservaLista from './routes/reserva-lista';
import PageReservaDetalhe from './routes/reserva-detalhe';
import PageMinhaConta from './routes/minha-conta';
import Page404 from './routes/404';


import './assets/css/default.css'

function App() {
  return (
      <Routes>
          <Route path="auth" element={<PageAuth />} />
          <Route path="auth/access-code/:email" element={<PageAuthAccessToken />} />
          <Route path="novo-usuario" element={<PageNovoUsuario />} />
          <Route path="novo-usuario/success" element={<PageNovoUsuarioMessage message="success" />} />
          <Route path="novo-usuario/error" element={<PageNovoUsuarioMessage message="error" />} />
          <Route path="/" element={<PageHome />} />
          <Route path="reserva-lista" element={<PageReservaLista />} />
          <Route path="reserva-detalhe/:id" element={<PageReservaDetalhe />} />
          <Route path="minha-conta" element={<PageMinhaConta />} />
          <Route path="*" element={<Page404 />} />
      </Routes>
  );
}

export default App;
