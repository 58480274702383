import React from "react";

import { Button } from "@mui/material";

import "./estilo.css";

function BtnResend({enable,handleBtnResendClick}) {
  
    

  if (enable) {
    return (
      <Button className="auth-access-token_btn-resend-access-code" onClick={handleBtnResendClick} fullWidth>
        Reenviar Código de acesso
      </Button>
    );
  } else {
    return (
      <Button
        disabled
        className="auth-access-token_btn-resend-access-code"
        fullWidth
      >
        Reenviar Código de acesso
      </Button>
    );
  }
}

export default BtnResend;
