import React, { useEffect, useState } from "react";

import CardNovidades from "../../components/CardNovidades";
import { apiGet } from "../../api.js";

import "./estilo.css";

function sliderTimer(transitionTime) {
  if (document.querySelector(".slider-card-novidades") == null) {
    return;
  }

  let slider = document.querySelector(".slider-card-novidades");
  let visible = slider.querySelector(".visible");
  if (visible == null) {
    return;
  }
  let next = visible.nextElementSibling;
  if (next == null) {
    next = slider.querySelector("li");
  }
  visible.classList.remove("visible");
  next.classList.add("visible");
  document
    .querySelector(".slider-card-novidades-bullets .active")
    .classList.remove("active");
  document
    .querySelectorAll(".slider-card-novidades-bullets li")[next.dataset.index].classList.add("active");
  setTimeout(() => {
    sliderTimer(transitionTime);
  }, transitionTime);
}

function SliderCardNovidades() {
  const [cards, setCards] = useState([]);

  let transitionTime = 5000;

  useEffect(() => {
    if (cards.length === 0) {
      try {
        apiGet("novidades").then((response) => {
          if (response && response.status === 200) {
            setCards(response.data);
          } else {
            window.location.href = "/auth";
          }
        });
      } catch (error) {
        alert("Houve uma falha de conexão com o servidor");
      }
    }

    setTimeout(() => {
      sliderTimer(transitionTime);
    }, transitionTime);
  }, []);

  return (
    <>
      <ul className="slider-card-novidades">
        {cards.map((item, x) => {
          return (
            <li data-index={x} key={x}  className={x === 0 ? "visible" : ""}>
              <CardNovidades
                imagem={item.image}
                titulo={item.title}
                texto={item.text}
                key={x} 
              />
            </li>
          );
        })}
      </ul>
      <ul
        className={`slider-card-novidades-bullets ${
          cards.length < 2 ? "d-none" : ""
        }`}
      >
        {cards.map((item, x) => {
          return <li key={x} className={x === 0 ? "active" : ""}></li>;
        })}
      </ul>
    </>
  );
}

export default SliderCardNovidades;
