import React from "react";

import Button from "@mui/material/Button";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

function ISButton(params) {
  function handleISButtonClick(e) {
    e.preventDefault();
    window.open(`mailto: ${params.email}?subject=Reserva ${params.king_ref} - ${params.costumer_ref}`);
  }
  return (
    <Button
      color="primary"
      variant="outlined"
      startIcon={<AssignmentIndIcon />}
      onClick={handleISButtonClick}
      sx={{ mt: 2 }}
    >
      {params.name} (IS)
    </Button>
  );
}

export default ISButton;
