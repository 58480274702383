import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Container, TextField, Button } from "@mui/material";
import AppBrand from "../../components/AppBrand";
import "./estilo.css";
import { Link } from "react-router-dom";
import { apiPost } from "../../api";

const PageNovoUsuario = () => {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [email, setEmail] = useState("");
  const [btnSendDisabled, setBtnSendDisabled] = useState(false);
  const RECAPTCHA_SITE_KEY = "6LcrrzwfAAAAAMCQrihnCul4oCakim_8nN0-XiL4";
  const recaptchaRef = React.createRef();

  async function handleSubmit(e) {
    e.preventDefault();
    setBtnSendDisabled(true);

    const token = await recaptchaRef.current.executeAsync();

    apiPost(`google-recaptcha-validate`, { token: token }).then((response) => {
      if (response && response.status === 200) {
        apiPost(`/usuarios/request-access`, {
          name: nome,
          email: email,
          phone: telefone,
          status: "requested_access",
          empresa_suggestion: empresa,
          role: "user"
        }).then((response) => {
            setBtnSendDisabled(false);
            if( response && response.status === 200 )
            {
               window.location.href = "/novo-usuario/success";
            }
            else 
            {
              window.location.href = "/novo-usuario/error";
            }
        });
      }
      else 
      {
       window.location.href = "/novo-usuario/error";
      }
    });

    

  }

  return (
    <Container className="text-align-center" maxWidth="sm" sx={{ mb: 4 }}>
      <form className="novo-usuario_form" onSubmit={handleSubmit} action="">
        <AppBrand size="small" />
        <h2 className="page-title">NOVO USUÁRIO</h2>
        <TextField
          required
          margin="normal"
          label="NOME"
          onChange={(e) => {
            setNome(e.target.value);
          }}
          fullWidth
        />
        <TextField
          required
          margin="normal"
          label="TELEFONE"
          onChange={(e) => {
            setTelefone(e.target.value);
          }}
          fullWidth
        />
        <TextField
          required
          margin="normal"
          label="EMPRESA"
          onChange={(e) => {
            setEmpresa(e.target.value);
          }}
          fullWidth
        />
        <TextField
          required
          type="email"
          margin="normal"
          label="E-MAIL"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          fullWidth
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={RECAPTCHA_SITE_KEY}
        />
        <Button variant="contained" disabled={btnSendDisabled} type="submit" fullWidth>
          Enviar
        </Button>
      </form>
      <Link to="/auth" className="text-decoration-none">
        <Button variant="outlined" fullWidth>
          Cancelar
        </Button>
      </Link>
    </Container>
  );
};

export default PageNovoUsuario;
