import axios from "axios";

import apiURL from "./apiURL";

const token = window.localStorage.getItem('token');
const api = axios.create({
    baseURL : apiURL,
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export async function apiGet(uri) {
  try{
      const response = await api.get(uri);
      return response;
  }
  catch( error )
  {
      return console.log(error);
  }
}


export async function apiPost(uri,data) {
    try{
        const response = await api.post(uri,data);
        return response;
    }
    catch( error )
    {
        return console.log(error);
    }
}


  
