import React from "react";

import Container from "@mui/material/Container";

import AppHeader from "../../components/AppHeader";
import SliderCardNovidades from "../../components/SliderCardNovidades";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";


const PageHome = () => {
  return (
    <Container className="page-with-fixed-header">
      <AppHeader />
      <Container maxWidth="sm" sx={{ pb : 4, pt: 3 }}>
        <SliderCardNovidades />
        <Link to="/reserva-lista" className="text-decoration-none">
            <Button variant="outlined" fullWidth sx={{ mt: 1 }}>
                Reservas
            </Button>
        </Link>
      </Container>
    </Container>
  );
};

export default PageHome;
