import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import { apiPost } from "../../api";

import "./estilo.css";
import { Link } from "react-router-dom";
import AppBrand from "../../components/AppBrand";
import moment from "moment/moment";

import { TextField, Button, Container } from "@mui/material";
import BtnResend from "../../components/BtnResendAccessCode";
import CountdownTimer from "../../components/CountdownTimer";


import Swal from "sweetalert2";

const PageAuthAccessToken = () => {
  let param = useParams();
  const [btnResendEnable, setBtnResendEnable] = useState(false);
  const [textFieldCodigoAcessoError, setTextFieldCodigoAcessoError] =
    useState(false);
  const [textFieldCodigoAcessoHelperText, setTextFieldCodigoAcessoHelperText] =
    useState("");
  const [accessCodeField, setAccessCodeField] = useState("");
  const [btnSubmitDisable, setBtnSubmitDisable] = useState(false);
  const ACCESS_CODE_EXPIRE = "access_code_expire";
  const RECAPTCHA_SITE_KEY = "6LcrrzwfAAAAAMCQrihnCul4oCakim_8nN0-XiL4";
  const recaptchaRef = React.createRef();

  function getAccessCodeEmail() {
    return param.email;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setBtnSubmitDisable(true);
    const email = getAccessCodeEmail();
    if (email === "e-mail não informado") {
      window.location.href = "/auth";
      return;
    }

    const token = await recaptchaRef.current.executeAsync();

    apiPost(`google-recaptcha-validate`, { token: token }).then((response) => {
      if (response && response.status === 200) {
        apiPost("usuarios/verify-access-code", {
          email: email,
          access_code: accessCodeField,
        }).then((response) => {
          setBtnSubmitDisable(false);
    
          if (response && response.status === 200) {
            window.localStorage.setItem("token", response.data.token);
            window.location.href = "/";
          } else {
            setTextFieldCodigoAcessoError(true);
            setTextFieldCodigoAcessoHelperText(
              "codigo de acesso inválido ou expirado"
            );
          }
        });
      }
      else 
      {
        setTextFieldCodigoAcessoError(true);
        setTextFieldCodigoAcessoHelperText(
          "Validação de segurança antibot bloqueou essa requisição."
        );
      }
    });

  }

  function handleBtnResendClick() {
    setBtnResendEnable(false);
    

    apiPost("usuarios/send-access-code", {
      email: param.email,
    }).then((data2) => {
      if (data2 && data2.status === 200) {
        window.localStorage.setItem(ACCESS_CODE_EXPIRE, moment().add(5, "minutes"));
        Swal.fire({
          title: "Código de acesso foi reenvido!",
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      }
      else 
      {
        Swal.fire({
          title: "Erro",
          text: "Houve um falha na tentativa de reenviar o código de acesso",
          icon: "error"
        })
      }
    });
  }

  function accessCodeFieldChange(e) {
    e.target.value.length <= 4 && setAccessCodeField(e.target.value);
  }

  return (
    <>
      <Container maxWidth="sm">
        <form
          className="auth-access-token_form"
          action=""
          onSubmit={handleSubmit}
        >
          <AppBrand size="big" />

          <div className="auth-access-token_label-code-sent">
            <p>ENVIAMOS UM CÓDIGO DE ACESSO PARA:</p>
            <strong>{getAccessCodeEmail()}</strong>
          </div>

          <TextField
            fullWidth
            error={textFieldCodigoAcessoError}
            helperText={textFieldCodigoAcessoHelperText}
            value={accessCodeField}
            onChange={accessCodeFieldChange}
            label="CÓDIGO DE ACESSO"
            required
          />
           <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
            />
          <Button
            variant="contained"
            type="submit"
            className="auth-access-token_btnEnviar"
            disabled={btnSubmitDisable}
            fullWidth
          >
            Enviar
          </Button>
        </form>
        <Link to="/auth" className="auth-access-token_btn-cancelar">
          <Button variant="outlined" fullWidth>
            Cancelar
          </Button>
        </Link>

        <BtnResend
          enable={btnResendEnable}
          handleBtnResendClick={handleBtnResendClick}
        />
        <CountdownTimer
          setBtnResendEnable={setBtnResendEnable}
          ACCESS_CODE_EXPIRE={ACCESS_CODE_EXPIRE}
        />
      </Container>
    </>
  );
};

export default PageAuthAccessToken;
