import React from "react";

import Typography from "@mui/material/Typography";

function ReferenceNumbers({ kingRef, costumerRef }) {
  return (
    <React.Fragment>
      <Typography
        className="list-reserva-item_king-reference"
        component="div"
        sx={{ fontWeight: 500, color: "#0602B5" }}
      >
        {kingRef}
      </Typography>
      <Typography
        className="list-reserva-item_costumer-reference"
        component="div"
        sx={{ fontWeight: 500, mb: 1 }}
      >
        {costumerRef}
      </Typography>
    </React.Fragment>
  );
}

export default ReferenceNumbers;
