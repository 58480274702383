import React from "react";

import moment from "moment";

import { Box } from "@mui/system";
import "./estilo.css";
import waves from "../../assets/img/waves.svg";
import ship from '../../assets/img/ship.png';
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function NavioCaminho({ ets, eta }) {

    const shipStep = 6; /// de 1 a 10

  return (
    <Box className="navio-caminho" sx={{ backgroundImage: `url(${waves})` }}>
      <Container maxWidth="sm" sx={{ position: 'relative' }}>
        <Stack
        direction="row"
        justifyContent="space-between"
        
        >
            <Chip
            label="ETS"
            color="primary"
            size="small"
            className="navio-caminho_chip"
            variant="outlined"
          />

            <Box className="navio-caminho_ship_way">
                <img className={`navio-caminho_ship navio-caminho_ship_step${shipStep}`} width="50" height="50" src={ship} alt="Navio Kingship" />
            </Box>

          <Chip
            label="ETA"
            color="primary"
            size="small"
            className="navio-caminho_chip"
            variant="outlined"
          />

        </Stack>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack
        direction="row"
        justifyContent="space-between"
        >
            <Typography variant="subtitle2">{ moment(ets).format('DD/MM/YYYY') }</Typography>  
            <Typography variant="subtitle2">{ moment(eta).format('DD/MM/YYYY') }</Typography>  
        </Stack>
      </Container>
    </Box>
  );
}

export default NavioCaminho;
