import React, { useState, useEffect } from 'react';

import './estilo.css';

import moment from "moment/moment";

function CountdownTimer({ setBtnResendEnable, ACCESS_CODE_EXPIRE }) {

    // create access token expire
  // window.localStorage.setItem('access_code_expire', 'Tue Mar 01 2022 10:54:05 GMT-0300' );
  const [ countDownTimer, setCountDownTimer ] = useState("");
    function getAccessCodeExpire()
  {
    return window.localStorage.getItem(ACCESS_CODE_EXPIRE);
  }  
  function countDown() {
    let accessCodeExpire = getAccessCodeExpire();
    if (accessCodeExpire == null) {
      return;
    }
    if (moment() > moment(accessCodeExpire)) {
      setCountDownTimer("");
      setBtnResendEnable(true);
      return;
    }
    accessCodeExpire = moment(accessCodeExpire);
    function zeroFill(n) {
      return ("00" + n).slice(-2);
    }
    let diff = Math.round((moment().diff(accessCodeExpire) * -1) / 1000);
    let minutes = zeroFill(Math.floor(diff / 60));
    let seconds = zeroFill(Math.floor(diff % 60));
    let timer = minutes + ":" + seconds;
    setCountDownTimer(timer);
    setTimeout(countDown, 1000);
  }
  useEffect(() => {
    countDown();
  }, []);


  return <strong className="auth-access-token_timer">{countDownTimer}</strong>;
}


export default CountdownTimer;