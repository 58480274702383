import React from "react";

import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";



function PageTitle({ title, urlBack }) {

  function goBack()
  {
    if( urlBack === "" )
    {
      window.history.go(-1);
    }
  }


  return (
    <Box sx={{ display: "flex"}}>
      <Link to={urlBack} onClick={goBack}>
        <IconButton
          size="large"
          color="primary"
          aria-label="Voltar para a página anterior"
          
          aria-haspopup="false"
        >
          <ArrowBack />
        </IconButton>
      </Link>

      <Typography variant="h6" color="text.primary" component="h3" sx={{ lineHeight: "2.4", ml: 1 }}>
        {title}
      </Typography>
    </Box>
  );
}

export default PageTitle;
