import React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import apiURL from "../../apiURL";


import "./estilo.css";


function CardNovidades({imagem, titulo, texto}) {
  return (
    <Card className="card-novidades">
      <CardMedia
        component="img"
        height="110"
        image={`${apiURL}/novidades/banner/${imagem}`}
        alt={titulo}
      />
      <CardContent>
        <Typography variant="h6">{titulo}</Typography>
        <Typography variante="body2" className="card-novidades_text">
        {texto}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CardNovidades;
