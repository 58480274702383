import React, { useEffect, useState } from "react";
import apiURL from "../../apiURL";
import { useParams } from "react-router-dom";
import moment from "moment";

import AppHeaderInternal from "../../components/AppHeaderInternal";
import ReferenceNumbers from "../../components/ReferenceNumbers";
import ISButton from "../../components/ISButton";
import Container from "@mui/material/Container";
import NavioCaminho from "../../components/NavioCaminho";
import "./estilo.css";
import ship from "../../assets/img/ship.png";
import container from "../../assets/img/container.svg";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { apiGet } from "../../api";

import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const PageReservaDetalhe = () => {
  let param = useParams();
  const [reserva, setReserva] = useState(null);

  useEffect(() => {
    // apiGet(`reservas/${param.id}`)
    // .then((response) => response.data)
    // .then((data) => {
    //   setReserva(data);
    // });

    apiGet(`reservas/${param.id}`).then((response) => {
      if (response && response.status === 200) {
        setReserva(response.data);
      } else {
        window.location.href = "/auth";
      }
    });
  }, []);

  function HBL() {
    if (reserva.hbl_arquivo !== null && reserva.hbl_arquivo !== "") {
      return (
        <Typography sx={{ textAlign: "right" }}>
          HBL:{" "}
          <a
            href={`${apiURL}/reservas/hbl-pdf/${reserva.hbl_arquivo}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1976d2' }}
          >
            {reserva.hbl}
            
          </a>
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ textAlign: "right" }}>HBL: {reserva.hbl}</Typography>
      );
    }
  }

  if (reserva != null) {
    return (
      <Container className="page-with-fixed-header-with-navio">
        <AppHeaderInternal
          title={`Reserva ${reserva.king_ref}`}
          urlBack="/reserva-lista"
        />
        <NavioCaminho ets={reserva.ets} eta={reserva.eta} />
        <Container maxWidth="sm" sx={{ pt: 2, pb: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <ReferenceNumbers
                kingRef={reserva.king_ref}
                costumerRef={reserva.costumer_ref}
              />
            </Box>
            <Box>
              <Typography sx={{ textAlign: "right", color: "text.secondary" }}>
                {moment(reserva.dt_criacao_reserva).format("DD/MM/YYYY h:mm")}
              </Typography>
              <HBL />
            </Box>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <TextField
              className="reserva-detalhe_origem-destino"
              sx={{ pr: 2 }}
              fullWidth
              label="Origem"
              variant="outlined"
              value={reserva.origem}
              disabled
            />
            <TextField
              className="reserva-detalhe_origem-destino"
              fullWidth
              label="Destino"
              variant="outlined"
              value={reserva.destino}
              disabled
            />
          </Stack>

          <Button
            color="primary"
            variant="outlined"
            endIcon={<OpenInNew />}
            sx={{ mt: 2, display: reserva.rastreamento ? "" : "none" }}
            size="large"
            fullWidth
            className="reserva-detalhe_btn-rastreamento"
          >
            Abrir Rastreamento
          </Button>

          <ISButton name={reserva.is_name} email={reserva.is_email} king_ref={reserva.king_ref} costumer_ref={reserva.costumer_ref} />

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ mt: 1, mb: 1 }}
          >
            <List>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar sx={{ minWidth: "45px", textAlign: "center" }}>
                  <img width="25" height="25" src={ship} alt="Navio Kingship" />
                </ListItemAvatar>
                <ListItemText primary={reserva.navio_nome} />
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar sx={{ minWidth: "45px", textAlign: "center" }}>
                  <img width="22" height="22" src={container} alt="Container" />
                </ListItemAvatar>
                <ListItemText
                  primary={reserva.container}
                  className="reserva-detalhe_numero-container"
                />
              </ListItem>
            </List>
          </Stack>

          <Typography
            component="div"
            variant="caption"
            sx={{ color: "text.secondary" }}
          >
            Importador
          </Typography>
          <Typography>
            {reserva.importador_nome} ({reserva.importador_documento})
          </Typography>
        </Container>
      </Container>
    );
  } else {
    return <React.Fragment />;
  }
};

export default PageReservaDetalhe;
