import React from "react";
import moment from "moment";

import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import ReferenceNumbers from "../ReferenceNumbers";


function ListReservasItem({ id, label, kingRef, costumerRef, origem, hbl, ets, eta }) {

  function handleItemClick()
  {
    window.location.href = `/reserva-detalhe/${id}`;
  }

  function chipColor()
  {
    let colors = { 'Em andamento' : 'success', 'Encerrado' : 'primary', 'Cancelado' : 'error' };
    return colors[label];
  }

  function dateFormat(date)
  {
    return moment(date).format("DD/MM/YYYY");
  }

  return (
    <ListItem onClick={handleItemClick} sx={{ cursor: 'pointer' }}>
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Box>
          <ReferenceNumbers kingRef={kingRef} costumerRef={costumerRef} />
          <Typography component="div" sx={{ fontSize: '14px' }}>Origem: {origem}</Typography>
          <Typography component="div" sx={{ fontSize: '14px' }}>HBL: {hbl}</Typography>
        </Box>
        <Box sx={{ width: "120px", textAlign: 'right' }}>
            <Chip color={ chipColor() } label={label}/>
            <Box sx={{ mt: 3 }}>
                <Typography component="p" sx={{ color: 'text.secondary', fontSize: '14px' }}>ETS: {dateFormat(ets)}</Typography>
                <Typography component="p" sx={{ color: 'text.secondary', fontSize: '14px'  }}>ETA: {dateFormat(eta)}</Typography>
            </Box>
        </Box>
      </Box>
      
    </ListItem>
  );
}

export default ListReservasItem;
