import React from "react";

import brand from "../../assets/img/brand.svg";
import "./estilo.css";

function AppBrand({ size }) {
  function imgSize()
  {
    return size === 'big' ? 200 : 104;
  }
  return (
    <div className={`app-brand app-brand-${size}`}>
      <img src={brand} width={imgSize()} height={imgSize()} alt="Kingship Line" />
      <h1>HUB</h1>
    </div>
  );
}

export default AppBrand;
