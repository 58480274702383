import React from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

import "./estilo.css";
import brand from "../../assets/img/brand.svg";

function AppHeader() {
  return (
    <AppBar className="app-header" position="fixed">
      <Container
        className="text-align-center"
        maxWidth="sm"
        sx={{ pl: 0, pr: 0 }}
      >
        <Toolbar className="app-header_toolbar">
          <img src={brand} alt="" width="100" />
          <Typography
            variant="h6"
            component="h2"
            sx={{ ml: 2, flexGrow: 1 }}
            className="app-header_app-name"
          >
            HUB
          </Typography>
          <Link to="/minha-conta">
            <IconButton
              size="large"
              color="primary"
              aria-label="conta do usuário atual"
              aria-haspopup="false"
            >
              <AccountCircle />
            </IconButton>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default AppHeader;
