import React, { useEffect, useState } from "react";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import ListReservasItem from "../ListReservasItem";
import { apiGet } from "../../api.js";
import moment from "moment";

function ListReservas({ filterStatus, recent }) {
  const [reservas, setReservas] = useState([]);
  const [filteredReservas, setFilteredReservas] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("Todos");
  const [currentRecent, setCurrentRecent] = useState(true);

  useEffect(() => {
    if (reservas.length === 0) {
      try {
        apiGet("reservas/empresa").then((response) => {
          if (response && response.status === 200) {
            setReservas(response.data);
            filterReservas(response.data);
          } else {
            window.location.href = "/auth";
          }
        });
      } catch (error) {
        alert("Houve uma falha de conexão com o servidor");
      }
      setCurrentStatus(filterStatus);
    }
    if (currentStatus !== filterStatus || recent !== currentRecent) {
      setCurrentStatus(filterStatus);
      setCurrentRecent(recent);
      filterReservas(reservas);
    }
  }, [filterReservas]);

  function filterReservas(array) {
    if (filterStatus !== "Todos") {
      let filterList = array.filter((item) => item.status === filterStatus);
      sortReservas(filterList);
    } else {
      sortReservas(array);
    }
  }

  function nenhumResultado() {
    if (filteredReservas.length === 0) {
      return (
        <Typography
          sx={{ textAlign: "center", mt: 3, color: "text.secondary" }}
        >
          Nenhum resultado foi encontrado.
        </Typography>
      );
    }
    return <React.Fragment></React.Fragment>;
  }

  function sortReservas(array) {
    setFilteredReservas(
      array.sort((a, b) => {
        if (moment(a.dt_criacao_reserva) < moment(b.dt_criacao_reserva)) {
          return recent ? -1 : 1;
        }
        if (moment(a.dt_criacao_reserva) > moment(b.dt_criacao_reserva)) {
          return recent ? 1 : -1;
        }
        return 0;
      })
    );
  }

  return (
    <List>
      <Divider sx={{ mb: 1 }} />
      {filteredReservas.map((item) => {
        return (
          <React.Fragment key={item.id}>
            <ListReservasItem
              id={item.id}
              label={item.status}
              kingRef={item.king_ref}
              costumerRef={item.costumer_ref}
              origem={item.origem}
              hbl={item.hbl}
              ets={item.ets}
              eta={item.eta}
            />
            <Divider sx={{ mt: 1, mb: 1 }} />
          </React.Fragment>
        );
      })}
      {nenhumResultado()}
    </List>
  );
}

export default ListReservas;
