import React from 'react';

const Page404 = () => 
{
    return (
        <>
        <h1>
            Página não encontrada
        </h1>
        </>
    )
}

export default Page404;