import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";

function BtnSortRecent({ recent, recentObservable }) {
  

  function LabelButton() {
    if ( recent )
    {
      return <Typography variant="button" sx={{ ml: 1 }}>Mais recentes</Typography>
    }
    else 
    {
      return <Typography variant="button" sx={{ ml: 1 }}>Mais antigas</Typography>;
    }
  }

  function ArrowIcon() {
    if (recent) {
      return <ArrowUpward fontSize="small" />;
    } else {
      return <ArrowDownward fontSize="small" />;
    }
  }

  function handleBtnClick() {
    recentObservable(!recent);
  }

  return (
    <Button onClick={handleBtnClick}>
      <ArrowIcon />
      <LabelButton />
    </Button>
  );
}

export default BtnSortRecent;
