import React from 'react';

import { Container, Button } from "@mui/material";
import AppBrand from '../../components/AppBrand';
import iconeSucesso from '../../assets/img/icone-sucesso.svg';
import iconeErro from '../../assets/img/icone-erro.svg';
import './estilo.css';
import { Link } from "react-router-dom";


function NovoUsuarioMessage({message})
{

    function MensagemSucesso()
    {
        return (
            <>
                <img src={iconeSucesso} width="90" height="90" alt="Sucesso" />
                <h3 className='novo-usuario-message_container-title'>
                    SOLICITAÇÃO REGISTRADA
                </h3>
                <p className='novo-usuario-message_container-text'>
                    Nossa equipe irá analisar e enviar instruções
                    de acesso o mais rápido possível.
                </p>
            </>
        )
    }

    function MensagemErro()
    {
        return (
            <>
                <img src={iconeErro} width="90" height="90" alt="Erro" />
                <h3 className='novo-usuario-message_container-title'>
                    FALHA NA SOLICITAÇÃO
                </h3>
                <p className='novo-usuario-message_container-text'>
                    Os dados não foram recebidos, tente novamente mais tarde 
                    ou entre em contato com a Kingshipline
                </p>
            </>
        )
    }

    function MessageDisplay()
    {
        let messages = {
            'success' : <MensagemSucesso />,
            'error' : <MensagemErro />
        }
        return messages[message];
    }

    

    return (
        <Container className="text-align-center" maxWidth="sm">
            <AppBrand size="small" />
            <MessageDisplay />
            <Link to="/auth" className="text-decoration-none">
                <Button variant="text" fullWidth>
                    Voltar a tela inicial
                </Button>
            </Link>
        </Container>
    )
}

export default NovoUsuarioMessage;
